<template>
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h6 class="text-base font-medium leading-tight tracking-wide text-gray-800 md:text-base">
            To proceed submit the One Time Password (OTP) sent to your email address 
            <span class="text-primary font-normal">{{ formData.email }}</span><br/>
            The OTP is valid for 5 minutes, after which you will need to send a new OTP.
        </h6>
        <div class="space-y-2 md:space-y-4">
            <div>
                <otp-input @on-complete="onComplete" />
                <div class="flex items-center justify-between mt-2 text-2xs font-thin">
                    <span class="text-gray-900 cursor-pointer hover:opacity-80" @click="$emit('on-submit', { component: 'loginEmail' })">Change Email ID</span>
                    <span @click="resendOTP()" :class="loginStates.otpResend ? 'text-gray-500' : 'text-primary hover:opacity-80 cursor-pointer'">Resend One-time Password?</span>
                </div>
            </div>
            <div class="text-center text-xs text-primary font-thin" :class="{ invisible: !(loginStates.otpResend && loginStates.counterValue) }">
                In case you have not received the OTP, you can resend it to your email address.
                You can resend a new OTP after <kbd> {{ loginStates.counterValue }}</kbd> seconds.
            </div>
            <button :disabled="errors.formData.otp.$invalid" type="submit" class="otp w-full text-white-text bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center" @click="$emit('on-submit', { component: 'loginClient' })">
                <Loader class="mr-2" v-if="loadingStates.otpVerify" />
                Submit
            </button>
        </div>
    </div>
</template>

<script>
const OtpInput = () => import("@shared/components/otp-input");
const Loader = () => import("@shared/loader");
import { getLoginInfo} from "./../../services";

export default {
    name: "login-email",
    props: {
        formData: {
            type: Object,
            default: () => {},
        },
        errors: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        loginStates: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        OtpInput,
        Loader,
    },
    methods: {
        onComplete(event) {
            this.$emit("on-change", { type: "otp", value: event });
        },
        resendOTP() {
            if (!this.loginStates.otpResend) {
                this.$emit("on-submit", { component: "resendOTP" });
            }
        },
    },
    mounted() {
        getLoginInfo();
    }
};
</script>

<style lang="scss" scoped></style>
